
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "settings",
  components: {},
  setup() {
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const saveChanges = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          router.push({ name: "overview" });
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", "Settings");
    });

    return {
      submitButton,
      saveChanges,
    };
  },
});
